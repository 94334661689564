import React from "react";
import { HeroImage, HeroImage1, HeroImage2 } from "../Assets";

export default function Hero() {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 mt-20">
      <div className="flex flex-col items-center gap-12 justify-between w-full mb-10 lg:flex-row">
        <div className="mb-16 lg:mb-0 lg:max-w-3xl lg:pr-5" id="hero">
          <div className="max-w-3xl mb-6">
          <h2 className="font-montserrat text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none max-w-3xl mb-6 md:hidden block">
          We use technology to solve difficult problems in today’s business
            </h2>
          <h2 className="font-montserrat leading-custom text-5xl font-semibold tracking-tight text-gray-900 md:block hidden">
            We use technology to
            </h2>
            <h2 className="font-montserrat leading-custom text-5xl font-semibold tracking-tight text-gray-900 md:block hidden">
           solve difficult problems 
            </h2>
            <h2 className="font-montserrat leading-custom text-5xl font-semibold tracking-tight text-gray-900 mb-6 md:block hidden">
            in today’s business
            </h2>
            <p className="font-montserrat text-gray-700 text-base md:text-lg text-justify">
            We specialize in designing professional websites, web applications, mobile apps, and corporate branding that effectively engage your audience, communicate your story, and generate leads to drive business expansion.
            </p>
          </div>
          <div className="flex items-center space-x-3">
            <a
              href="/"
              className="w-48 h-14 text-center text-whiteShade transition duration-300 hover:shadow-lg bg-nudeColor2 flex justify-center items-center rounded-tl-[20px] rounded-tr-[0px] rounded-bl-[20px] rounded-br-[20px]"
            >
              View our product
            </a>
          </div>
        </div>
        <div className="flex items-center justify-center lg:w-1/2">
          <div className="w-[70%] relative">
          <img className="absolute -left-1/2 bottom-12" src={HeroImage1} alt="" />
            <img className="object-cover" src={HeroImage} alt="" />
            <img className="absolute -bottom-1/4" src={HeroImage2} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
