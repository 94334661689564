import React from "react";
import { Navbar } from "../Components";
import { Footer } from "../UI";
import "../UI/Style.css";

export default function Privacy() {
  return (
    <div className="flex flex-col">
      <div className="mb-20">
        <Navbar />
      </div>
      <div className="bg-nudeColor h-80 flex justify-center items-center">
        <div className="px-4 py-16 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-32">
          <div className="flex flex-col items-center gap-3 justify-center">
            <div className="flex flex-col gap-2 mb-20 text-center">
              <h1 className="text-4xl text-whiteShade font-bold">
                Privacy Policy
              </h1>
              <h1 className="text-xl text-grayShade">
                At Viindo Technologies, we are commited to protecting your
                privacy and ensuring the security of your personal information
              </h1>
            </div>
          </div>
        </div>
      </div>

      {/* Privacy Body */}
      <div>
        <div
          className="flex justify-start items-center py-20 lg:mt-20 lg:mb-80"
        >
          <div className="bg-nudeColor h-96"></div>
          <div
            className="px-10 pl-5 flex flex-col gap-20"
          >
            <h1 className="text-nudeColor text-3xl">
              Effective Date: 2023-11-24
            </h1>
            <main className="flex flex-col gap-10">
              {/* Section One */}
              <section className="flex flex-col gap-5">
                <h2 className="text-nudeColor text-2xl">Introduction</h2>
                <p className="text-textColor">
                  This Privacy Policy ("Policy") describes how Viindo Technology
                  ("Viindo," "we," "us," or "our") collects, uses, and discloses
                  your personal information through our Viindo social ecommerce
                  app ("App").
                </p>
              </section>

              {/* Section Two */}
              <section className="flex flex-col gap-5">
                <h2 className="text-nudeColor text-2xl">
                  Information we collect
                </h2>
                <h3 className="text-sm text-textColor">
                  We collect the following types of information from and about
                  you:
                </h3>
                <p>
                  <span className="font-bold text-nudeColor">
                    Personal Information:
                  </span>{" "}
                  <span className="text-textColor text-sm">
                    This includes your name, email address, phone number,
                    mailing address, and other information you voluntarily
                    provide to us.
                  </span>
                </p>
                <p>
                  <span className="font-bold text-nudeColor">
                    Device Information:
                  </span>{" "}
                  <span className="text-textColor text-sm">
                    This includes information about the device you use to access
                    the App, such as your device type, operating system, browser
                    version, IP address, and unique device identifier.
                  </span>
                </p>
                <p>
                  <span className="font-bold text-nudeColor">
                    Usage Information:
                  </span>{" "}
                  <span className="text-textColor text-sm">
                    This includes information about how you use the App, such as
                    your browsing history, purchase history, and search history.
                  </span>
                </p>
                <p>
                  <span className="font-bold text-nudeColor">
                    Location Information:
                  </span>{" "}
                  <span className="text-textColor text-sm">
                    This includes information about your location when you use
                    the App, if you have enabled location services.
                  </span>
                </p>
              </section>

              {/* Section Three */}
              <section className="flex flex-col gap-5">
                <h2 className="text-nudeColor text-2xl">
                  How We Use Your Information
                </h2>
                <h3 className="text-sm text-textColor">
                  We use your information to:
                </h3>
                <ul className="list-disc ml-5 text-textColor">
                  <li>Provide and operate the App.</li>
                  <li>Process your purchases and orders.</li>
                  <li>Send you marketing and promotional materils.</li>
                  <li>Personalize your experience on the App.</li>
                  <li>Improve the App and our services.</li>
                  <li>Analyze and aggregate data for research purposes.</li>
                  <li>Protect our rights and property.</li>
                  <li>Comply with applicble laws and regulations.</li>
                </ul>
              </section>

              {/* Section Four */}
              <section className="flex flex-col gap-5">
                <h2 className="text-nudeColor text-2xl">
                  Sharing Your Information
                </h2>
                <h3 className="text-sm text-textColor">
                  We may share your information with third-party service
                  providers who help us operate the App and provide our services
                  to you. These service providers are not authorized to use or
                  disclose your information except as necessary to provide
                  services to us.
                </h3>
                <p className="text-textColor text-sm">
                  We may also share your information with other third parties if
                  we believe that doing so is necessary to:
                </p>
                <ul className="list-disc ml-5 text-textColor">
                  <li>Comply with applicable laws and regulations.</li>
                  <li>Protect our rights and property.</li>
                  <li>Protect the safety of our users or the public.</li>
                </ul>
              </section>

              {/* Section Five */}
              <section className="flex flex-col gap-5">
                <h2 className="text-nudeColor text-2xl">Your Choices</h2>
                <p className="text-textColor">
                  You may opt out of receiving marketing emails from us by
                  following the unsubscribe instructions in any marketing email
                  you receive. <br /> You may also request to access, correct,
                  or delete your personal information by contacting us at
                  info@viindo.com
                </p>
              </section>

              {/* Section Six */}
              <section className="flex flex-col gap-5">
                <h2 className="text-nudeColor text-2xl">Data Security</h2>
                <p className="text-textColor">
                  We use industry-standard security measures to protect your
                  information from unauthorized access, use, disclosure,
                  alteration, or destruction
                </p>
              </section>

              {/* Section Seven */}
              <section className="flex flex-col gap-5">
                <h2 className="text-nudeColor text-2xl">
                  Changes to Our Privacy Policy
                </h2>
                <p className="text-textColor">
                  We may update this Policy at any time. If we make any material
                  changes to this Policy, we will notify you by email or by
                  posting a notice on the App.
                </p>
              </section>

              {/* Section Eight */}
              <section className="flex flex-col gap-5">
                <h2 className="text-nudeColor text-2xl">Contact Us</h2>
                <p className="text-textColor">
                  If you have any questions about this Policy, please contact us
                  at info@viindo.com
                </p>
              </section>
            </main>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="bg-lightBlank">
        <Footer />
      </div>
    </div>
  );
}
