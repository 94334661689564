import React from "react";
import { Body, Footer, Hero } from "../UI";
import { Navbar } from "../Components";

export default function Home() {
  return (
    <div className="w-[100vw] overflow-x-hidden">
      <div className="md:h-screen bg-blankColor font-montserrat">
      <Navbar />
      <Hero />
      </div>
     
      <Body />
      {/* Footer */}
      <div className="bg-[#404040]">
        <Footer />
      </div>
    </div>
  );
}
