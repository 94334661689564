import { useState, useEffect } from "react";
import { Logo } from "../Assets";
import "../UI/Style.css";
import { Link } from "react-router-dom";

export default function Nav() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 100;

      setHasScrolled(scrollPosition > scrollThreshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`bg-${hasScrolled ? "blankColor" : "blankColor"} ${
        hasScrolled ? "text-white" : "text-white"
      } fixed top-0 w-[100vw] z-50`}
    >
      <div className="px-3 py-5 mx-auto sm:max-w-xl md:max-w-full md:px-24 lg:px-48 sm:px-14">
        <div className="relative flex items-center justify-between">
          <a
            href="/"
            aria-label="Viindo"
            title="Viindo"
            class="inline-flex items-center"
          >
            <img src={Logo} alt="Logo" className="w-10 h-10 text-teal-accent-400" />
          </a>
          <ul class="flex items-center hidden space-x-8 lg:flex ml-96 font-poppins">
            <li className="underln">
              <a
                href="/#product"
                aria-label="Our product"
                title="Our product"
                class="font-medium tracking-wide text-black transition-colors duration-200 hover:text-teal-accent-400"
              >
                Product
              </a>
            </li>
            <li className="underln">
              <Link
                to={"/#about"}
                aria-label="About"
                title="About"
                class="font-medium tracking-wide text-black transition-colors duration-200 hover:text-teal-accent-400"
              >
                About Us
              </Link>
            </li>
            <li className="underln">
              <Link
                to={"/privacy"}
                aria-label="Privacy"
                title="Privacy"
                class="font-medium tracking-wide text-black transition-colors duration-200 hover:text-teal-accent-400 cursor-pointer"
              >
                Privacy
              </Link>
            </li>
            <li className="underln">
              <Link
                to={"/faqs"}
                aria-label="Faqs"
                title="Faqs"
                class="font-medium tracking-wide text-black transition-colors duration-200 hover:text-teal-accent-400"
              >
                FAQs
              </Link>
            </li>
          </ul>
          <ul class="flex items-center hidden space-x-8 lg:flex">
            <li>
              <Link
                to={"/#contact"}
                class="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-green transition duration-200 rounded-tl-[20px] rounded-tr-[5px] rounded-bl-[20px] rounded-br-[20px] shadow-md hover:bg-green text-nudeColor2 border-nudeColor2 border-2 focus:shadow-outline focus:outline-none"
                aria-label="Contact"
                title="Contact"
              >
                Contact Us
              </Link>
            </li>
          </ul>
          <div class="lg:hidden">
            <button
              aria-label="Open Menu"
              title="Open Menu"
              class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline z-[200]"
              onClick={() => setIsMenuOpen(true)}
            >
              <svg class="w-5 text-black" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                />
                <path
                  fill="currentColor"
                  d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                />
                <path
                  fill="currentColor"
                  d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                />
              </svg>
            </button>
            {isMenuOpen && 
            (
              <div class="absolute top-0 left-0 w-full">
                <div class="p-5 bg-white border rounded shadow-sm">
                  <div class="flex items-center justify-between mb-4">
                    <div>
                      <a
                        href="/"
                        aria-label="Company"
                        title="Company"
                        class="inline-flex items-center"
                      >
                        <img
                          src={Logo}
                          alt="Logo"
                          className="w-10 h-10 text-teal-accent-400"
                        />
                      </a>
                    </div>
                    <div>
                      <button
                        aria-label="Close Menu"
                        title="Close Menu"
                        class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <nav>
                    <ul class="space-y-4">
                      <li>
                        <a
                          href="/#product"
                          aria-label="Product"
                          title="Product"
                          class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          Product
                        </a>
                      </li>
                      <li>
                        <Link
                          to={"/#about"}
                          aria-label="About"
                          title="About"
                          class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/privacy"}
                          aria-label="Privacy"
                          title="Privacy"
                          class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          Privacy
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/faqs"}
                          aria-label="Faqs"
                          title="Faqs"
                          class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          FAQs
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/#contact"}
                          aria-label="Contact"
                          title="Contact"
                          class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
