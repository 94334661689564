import React from "react";
import { Navbar } from "../Components";
import { Footer } from "../UI";
import '../UI/Style.css'

export default function Terms() {
  return (
    <div className="flex flex-col">
      <div className="mb-20">
        <Navbar />
      </div>
      <div className="bg-nudeColor h-80 flex justify-center items-center">
        <div className="px-4 py-16 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-32">
          <div className="flex flex-col items-center gap-3 justify-center">
            <div className="flex flex-col gap-2 mb-20 text-center">
              <h1 className="text-4xl text-whiteShade font-bold">
                Terms and Conditions
              </h1>
              <h1 className="text-xl text-grayShade">
                Please read the terms and conditions before using our service
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center lg:py-48 sm:p-10" id="itemBodyy">
        <div className="bg-termsColor flex flex-col gap-3 lg:p-20 w-1/2" id="itemBody">
          <p>
            These terms and conditions outline the rules and regulations for the
            use of Viindo Technology Limited's Website, located at viindo.com.
          </p>
          <p>
            By accessing this website we assume you accept these terms and
            conditions. Do not continue to use Viindo Tech if you do not agree
            to take all of the terms and conditions stated on this page. The
            following terminology applies to these Terms and Conditions, Privacy
            Statement and Disclaimer Notice and all Agreements: "Client", "You"
            and "Your" refers to you, the person log on this website and
            compliant to the Company's terms and conditions.
          </p>{" "}
          <p>
            "The Company", "Ourselves", "We", "Our" and "Us", refers to our
            Company. "Party", "Parties", or "Us", refers to both the Client and
            ourselves. All terms refer to the offer, acceptance and
            consideration of payment necessary to undertake the process of our
            assistance to the Client in the most appropriate manner for the
            express purpose of meeting the Client's needs in respect of
            provision of the Company's stated services, in accordance with and
            subject to, prevailing law of Nigeria. Any use of the above
            terminology or other words in the singular, plural, capitalization
            and/or he/she or they, are taken as interchangeable and therefore as
            referring to the same.
          </p>
        </div>
      </div>
      {/* Footer */}
      <div className="bg-lightBlank">
        <Footer />
      </div>
    </div>
  );
}
