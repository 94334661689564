import React, {useState} from "react";
import { AboutImage, ProductImage, app, uiux, business, dd, product, games, graphics, contract, icon1, samuel, phone, location, mail } from "../Assets";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useCollapse } from 'react-collapsed';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { useForm, ValidationError } from '@formspree/react';

export default function Body() {
  const [state, handleSubmit] = useForm("moqggqle");
  const [isExpanded1, setExpanded1] = useState(false);
  const { getCollapseProps: getCollapseProps1, getToggleProps: getToggleProps1 } = useCollapse({ isExpanded: isExpanded1 });
  
  const [isExpanded2, setExpanded2] = useState(false);
  const { getCollapseProps: getCollapseProps2, getToggleProps: getToggleProps2 } = useCollapse({ isExpanded: isExpanded2 });

  const [isExpanded3, setExpanded3] = useState(false);
  const { getCollapseProps: getCollapseProps3, getToggleProps: getToggleProps3 } = useCollapse({ isExpanded: isExpanded3 });

  const [isExpanded4, setExpanded4] = useState(false);
  const { getCollapseProps: getCollapseProps4, getToggleProps: getToggleProps4 } = useCollapse({ isExpanded: isExpanded4 });

  const [isExpanded5, setExpanded5] = useState(false);
  const { getCollapseProps: getCollapseProps5, getToggleProps: getToggleProps5 } = useCollapse({ isExpanded: isExpanded5 });


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const data = [
    {
      id: 1,
      image: app,
      title: 'App development',
      message: 'we develope swift and sophisticated software solutions for businesses, regardless of complexity.',
    },
    {
      id: 2,
      image: uiux,
      title: 'UI/UX DESIGN',
      message: 'We bring expertise from all stages of design, from research to prototype',
    },
    {
      id: 3,
      image: business,
      title: 'Business Analytics',
      message: 'We provide comprehensive Business Analytics services, leveraging data insights to optimize operations.',
    },
    {
      id: 4,
      image: dd,
      title: 'Product Manager',
      message: 'We guide products through their lifecycle from inception to launch, ensuring their success in the market.',
    },
    {
      id: 5,
      image: product,
      title: '2D/3D Development',
      message: 'We develop captivating digital content and immersive experiences using the latest technology and creative techniques.',
    },
    {
      id: 6,
      image: games,
      title: 'Games Development',
      message: 'we develop swift and sophisticated software solutions for businesses, regardless of complexity.',
    },
    {
      id: 7,
      image: graphics,
      title: 'Graphics Design',
      message: 'we craft visually appealing artwork and designs using creativity and digital tools to communicate messages effectively.',
    },
    {
      id: 8,
      image: contract,
      title: 'Contract Development',
      message: 'we develop swift and sophisticated software solutions for businesses, regardless of complexity.',
    },
  ];
  return (
    <div className="bg-blankColor2">

  {/* Body  */}
  <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-4" id="product">
  <div className="grid mb-12 gap-8 items-center md:grid-cols-1 lg:grid-cols-3 lg:grid-flow-col lg:grid-rows-1">
  <div className="lg:col-span-1">
    <h2 className="md:text-4xl text-black font-semibold md:leading-custom2" id="h1">Want you to boost your business  growth? solution is here</h2>
  </div>
  <div className="lg:col-span-2">
    <p className="font-montserrat text-gray-700 text-base md:text-lg text-justify">
      We specialize in designing professional websites, web applications, mobile apps, and corporate branding that effectively engage your audience, communicate your story, and generate leads to drive business expansion.
    </p>
  </div>
</div>
<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-8 my-8 md:my-28">
      {data.map((item) => (
      <div key={item.id} className="bg-white px-4 py-9 flex flex-col items-start rounded-3xl hover:bg-nudeColor2 group">
      <div className="p-6 bg-blankColor3 group-hover:bg-white rounded-full mb-5">
        <img src={item.image} alt={item.title} className="object-cover" />
      </div>
      <h2 className="font-semibold mb-2 text-black text-lg group-hover:text-white">{item.title}</h2>
      <p className="text-base text-grayShade2 group-hover:text-white">{item.message}</p>
    </div>
      ))}
    </div>
      </div>

      <div className="relative py-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-4xl md:px-24 lg:px-8 lg:py-20 bg-nudeColor md:rounded-tr-[200px] md:rounded-bl-[200px]">
        <div className="relative">
          <div className="flex flex-col mb-8 justify-center gap-5 items-center">
            {/* About Us */}
            <div id="about">
              <h1 className="flex justify-center items-center text-blankColor font-montserrat text-base font-semibold">
              
                 About us
              </h1>
            </div>
            <h1 className="text-4xl text-whiteShade font-montserrat font-bold" id="h1">Who We Are</h1>
          </div>
          <div className="grid gap-0 lg:grid-cols-2 lg:px-48">
            <div className="flex items-center justify-center lg:w-full">
              <div className="w-full" id="aboutImg">
                <img className="object-cover w-[90%]" src={AboutImage} alt="" />
              </div>
            </div>

            <div className="flex justify-center items-center" id="aboutText">
              <div className="flex flex-col gap-3 max-w-3xl">
                <h1 className="text-whiteShade text-3xl font-semibold">
                  Revolutionizing e-commerce with Viindo Cart
                </h1>
                <div className="flex flex-col gap-3 max-w-2xl text-grayShade">
                  <p>
                  Viindo technology is an IT development company dedicated to offering impactful, strategic, and sustainable IT solutions to elevate businesses into global brands.
Viindo Technologies extends its reach with ViindoCart, an e-commerce platform.  ViindoCart redefines shopping, fostering social, enjoyable interactions beyond traditional confines


                  </p>
                  <p>
                    We are at the forefront of social e-commerce, shaping the
                    future of online shopping
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Products  */}
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20" id="product">
        <div className="flex flex-col mb-32 justify-center gap-5 items-center">
          <div>
          <h1 className="flex justify-center items-center text-blankShade font-montserrat text-base font-semibold">
              
             Product
           </h1>
          </div>
          <h1 className="text-7xl text-nudeColor font-bold" id="h1">What we offer</h1>
        </div>
        <div className="flex flex-col items-center justify-between w-full mb-10 lg:flex-row">
          <div className="mb-16 lg:mb-0 lg:max-w-lg lg:pr-5">
            <div className="max-w-xl mb-6" id="productText">
            <img className="object-cover md:w-[40%] mb-6 w-full" src={icon1} alt="" />
              <h2 className="font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none max-w-lg mb-6">
                Viindo Cart- The Social E-commerce App
              </h2>
              <p className="text-gray-700 text-base md:text-lg">
                Discover the transformative power of social e-commerce with
                Viindo Cart, the revolutionary app that seamlessly integrates
                your online store with social media, empowering businesses to
                thrive in the digital era
              </p>
            </div>
            <div className="flex items-center space-x-3" id="productDownload">
              <a
                href="/"
                className="w-32 transition duration-300 hover:shadow-lg"
              >
                <img
                  src="https://kitwind.io/assets/kometa/app-store.png"
                  className="object-cover object-top w-full h-auto mx-auto"
                  alt=""
                />
              </a>
              <a
                href="/"
                className="w-32 transition duration-300 hover:shadow-lg"
              >
                <img
                  src="https://kitwind.io/assets/kometa/google-play.png"
                  className="object-cover object-top w-full h-auto mx-auto"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="flex items-center justify-center lg:w-1/2">
            <div className="w-full">
              <img className="object-cover" src={ProductImage} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="py-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-4xl md:px-24 lg:px-8 lg:py-20 bg-[#FCF6F1]">
      <Slider {...settings}>
       {/* 1 */}
      <div className="grid gap-0 lg:grid-cols-[1fr_5fr] lg:px-48">
  <div className="flex items-end justify-end lg:w-full">
    <div className="w-full lg:block hidden" id="aboutImg">
      <img className="lg:mt-28 lg:ml-48" src={samuel} alt="" />
    </div>
  </div>
  <div className="flex justify-center items-center" id="aboutText">
    <div className="flex flex-col gap-3 max-w-3xl">
      <h1 className="text-nudeColor2 text-3xl font-bold">
        people talking about us
      </h1>
      <div className="flex flex-col gap-1 max-w-2xl text-nudeColor2">
        <p>
          Viindo technology is an IT development company dedicated to offering impactful, strategic, and sustainable IT solutions to elevate businesses into global brands.Viindo Technologies extends its reach with ViindoCart, an e-commerce platform. ViindoCart redefines shopping, fostering social, enjoyable interactions beyond traditional confines.
        </p>

       <h1 className="text-nudeColor2 text-xl font-bold">
       Samuel John
      </h1>
      <p>
      viindo technology CEO
        </p>
      </div>
    </div>
    
  </div>
</div>

{/* 2 */}

<div className="grid gap-0 lg:grid-cols-[1fr_5fr] lg:px-48">
  <div className="flex items-end justify-end lg:w-full">
    <div className="w-full lg:block hidden" id="aboutImg">
      <img className="lg:mt-28 lg:ml-48" src={samuel} alt="" />
    </div>
  </div>
  <div className="flex justify-center items-center" id="aboutText">
    <div className="flex flex-col gap-3 max-w-3xl">
      <h1 className="text-nudeColor2 text-3xl font-bold">
        people talking about us
      </h1>
      <div className="flex flex-col gap-1 max-w-2xl text-nudeColor2">
        <p>
          Viindo technology is an IT development company dedicated to offering impactful, strategic, and sustainable IT solutions to elevate businesses into global brands.Viindo Technologies extends its reach with ViindoCart, an e-commerce platform. ViindoCart redefines shopping, fostering social, enjoyable interactions beyond traditional confines.
        </p>

       <h1 className="text-nudeColor2 text-xl font-bold">
       Samuel John
      </h1>
      <p>
      viindo technology CEO
        </p>
      </div>
    </div>
    
  </div>
</div>

{/* 3 */}

<div className="grid gap-0 lg:grid-cols-[1fr_5fr] lg:px-48">
  <div className="flex items-end justify-end lg:w-full">
    <div className="w-full lg:block hidden" id="aboutImg">
      <img className="lg:mt-28 lg:ml-48" src={samuel} alt="" />
    </div>
  </div>
  <div className="flex justify-center items-center" id="aboutText">
    <div className="flex flex-col gap-3 max-w-3xl">
      <h1 className="text-nudeColor2 text-3xl font-bold">
        people talking about us
      </h1>
      <div className="flex flex-col gap-1 max-w-2xl text-nudeColor2">
        <p>
          Viindo technology is an IT development company dedicated to offering impactful, strategic, and sustainable IT solutions to elevate businesses into global brands.Viindo Technologies extends its reach with ViindoCart, an e-commerce platform. ViindoCart redefines shopping, fostering social, enjoyable interactions beyond traditional confines.
        </p>

       <h1 className="text-nudeColor2 text-xl font-bold">
       Samuel John
      </h1>
      <p>
      viindo technology CEO
        </p>
      </div>
    </div>
    
  </div>
</div>
</Slider>
     
      </div>

      {/* Faq */}

      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-28 lg:py-28">
      <h1 className="text-5xl text-nudeColor font-bold text-center mb-10" id="h1">Frequently Ask Question</h1>
      <div className="mb-4 border-b">
        <button
          {...getToggleProps1({
            onClick: () => setExpanded1(prev => !prev),
          })}
          className="w-full flex justify-between items-center p-4 bg-nudeColor2 text-lg font-medium rounded text-white"
        >
          What makes Viindo Cart different from traditional e-commerce platforms?
          {isExpanded1 ? <FaMinus /> : <FaPlus />}
        </button>
        <div {...getCollapseProps1()}>
          <div className="p-4 bg-white">
            Viindo Cart redefines shopping by fostering social and enjoyable interactions beyond traditional confines. It extends the reach of Viindo Technologies by offering an e-commerce platform that emphasizes impactful and sustainable IT solutions.
          </div>
        </div>
      </div>
      
      <div className="mb-4 border-b">
        <button
          {...getToggleProps2({
            onClick: () => setExpanded2(prev => !prev),
          })}
          className="w-full flex justify-between items-center p-4 bg-white text-lg font-medium rounded text-nudeColor2 border border-nudeColor2"
        >
          Viindo technology is an IT development company dedicated to offering impactful IT solutions.
          {isExpanded2 ? <FaMinus /> : <FaPlus />}
        </button>
        <div {...getCollapseProps2()}>
          <div className="p-4 bg-white">
            Viindo technology is an IT development company dedicated to offering impactful, strategic, and sustainable IT solutions to elevate businesses into global brands. With a focus on innovation and excellence, Viindo Technologies is at the forefront of transforming businesses through technology.
          </div>
        </div>
      </div>
      <div className="mb-4 border-b">
        <button
          {...getToggleProps3({
            onClick: () => setExpanded3(prev => !prev),
          })}
          className="w-full flex justify-between items-center p-4 bg-white border border-nudeColor2 text-lg font-medium rounded text-nudeColor2"
        >
          What makes Viindo Cart different from traditional e-commerce platforms?
          {isExpanded3 ? <FaMinus /> : <FaPlus />}
        </button>
        <div {...getCollapseProps3()}>
          <div className="p-4 bg-white">
            Viindo Cart redefines shopping by fostering social and enjoyable interactions beyond traditional confines. It extends the reach of Viindo Technologies by offering an e-commerce platform that emphasizes impactful and sustainable IT solutions.
          </div>
        </div>
      </div>
      
      <div className="mb-4 border-b">
        <button
          {...getToggleProps4({
            onClick: () => setExpanded4(prev => !prev),
          })}
          className="w-full flex justify-between items-center p-4 bg-white border border-nudeColor2 text-lg font-medium rounded text-nudeColor2"
        >
          Viindo technology is an IT development company dedicated to offering sustainable IT solutions.
          {isExpanded4 ? <FaMinus /> : <FaPlus />}
        </button>
        <div {...getCollapseProps4()}>
          <div className="p-4 bg-white">
            Viindo technology is an IT development company dedicated to offering impactful, strategic, and sustainable IT solutions to elevate businesses into global brands. With a focus on innovation and excellence, Viindo Technologies is at the forefront of transforming businesses through technology.
          </div>
        </div>
      </div>

      <div className="mb-4 border-b">
        <button
          {...getToggleProps5({
            onClick: () => setExpanded5(prev => !prev),
          })}
          className="w-full flex justify-between items-center p-4 bg-white border border-nudeColor2 text-lg font-medium rounded text-nudeColor2"
        >
          Viindo technology is an IT development company dedicated to offering strategic IT solutions.
          {isExpanded5 ? <FaMinus /> : <FaPlus />}
        </button>
        <div {...getCollapseProps5()}>
          <div className="p-4 bg-white">
            Viindo technology is an IT development company dedicated to offering impactful, strategic, and sustainable IT solutions to elevate businesses into global brands. With a focus on innovation and excellence, Viindo Technologies is at the forefront of transforming businesses through technology.
          </div>
        </div>
      </div>
      
      </div>

      {/* Contact Us */}

      <div className="px-4 pb-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pb-20 bg-blankColor2" id="product">
        <div className="flex flex-col mb-32 justify-center gap-5 items-center">
          <div>
          <h1 className="flex justify-center items-center text-blankShade font-montserrat text-base font-semibold">
              
          Contact
           </h1>
          </div>
          <h1 className="text-7xl text-nudeColor font-bold" id="h1">Get in touch with us </h1>
        </div>
        <div className="flex flex-col items-center justify-between w-full mb-10 lg:flex-row">
          <div className="mb-16 lg:mb-0 lg:max-w-lg lg:pr-5">
            <div className="max-w-xl mb-6" id="productText">
            <h1 className="text-blankShade font-montserrat text-base font-semibold mb-3">
            Contact Us
               </h1>
               <h3 className="text-sm font-normal text-[#656565] mb-5">
               For inquiries, partnerships, or support, Reach out to us through the details below or fill in the contact form
          </h3>
             <div>
              <div className="flex items-center justify-start gap-6 mb-3">
              <div className="p-6 bg-nudeColor2 group-hover:bg-white rounded-md">
        <img src={location} alt='location' className="object-cover" />
      </div>
      <div>
      <h1 className="text-black font-montserrat text-base font-semibold mb-1">
      Our Location
               </h1>
               <p className="text-textColor">Ogba, Lagos State Nigeria

</p>
      </div>
                
              </div>

              <div className="flex items-center justify-start gap-6 mb-3">
              <div className="p-6 bg-nudeColor2 group-hover:bg-white rounded-md">
        <img src={phone} alt='phone' className="object-cover" />
      </div>
      <div>
      <h1 className="text-black font-montserrat text-base font-semibold mb-1">
      phone Number
               </h1>
               <p className="text-textColor">+234 9075253100

</p>
      </div>
                
              </div>

              <div className="flex items-center justify-start gap-6 mb-3">
              <div className="p-6 bg-nudeColor2 rounded-md">
        <img src={mail} alt='mail' className="object-cover" />
      </div>
      <div>
      <h1 className="text-black font-montserrat text-base font-semibold mb-1">
      Email Address
               </h1>
               <p className="text-textColor">info@viindo.com
</p>
      </div>
                
              </div>
             </div>
            </div>
          
          </div>
          <div className="relative bg-nudeColor2 rounded shadow-2xl p-7 sm:p-10 sm:w-[100px] lg:w-[500px]">
            {
              state.succeeded ? (
                <div>
                <p className="text-white">Thanks for submitting! I'll get back to you soon.</p>
              </div>
              ) : (
                <form onSubmit={handleSubmit}>
                <div className="mb-1 sm:mb-2">
                  <label
                    htmlFor="name"
                    className="inline-block mb-1 font-medium"
                  >
                    {/* Name */}
                  </label>
                  <input
                    placeholder="Your Name"
                    required
                    type="text"
                    className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                    id="name"
                    name="name"
                  />
                   <ValidationError
                    prefix="Name"
                    field="name"
                    errors={state.errors}
                  />
                </div>
                <div className="mb-1 sm:mb-2">
                  <label
                    htmlFor="email"
                    className="inline-block mb-1 font-medium"
                  >
                    {/* E-mail */}
                  </label>
                  <input
                    placeholder="Email"
                    required
                    type="text"
                    className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                    id="email"
                    name="email"
                  />
                  <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                  />
                </div>

                <div className="mb-1 sm:mb-2">
                  <label
                    htmlFor="phone"
                    className="inline-block mb-1 font-medium"
                  >
                    {/* E-mail */}
                  </label>
                  <input
                    placeholder="Phone"
                    required
                    type="text"
                    className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                    id="phone"
                    name="phone"
                  />
                    <ValidationError
                    prefix="Phone Number"
                    field="phone"
                    errors={state.errors}
                  />
                </div>

                <div className="relative w-full">
                  <input
                    placeholder="Message..."
                    required
                    type="text"
                    className="w-full h-52 border border-gray-300 rounded-md pl-10 focus:outline-none"
                    id="message"
                    name="message"
                  />
                    <ValidationError
                    prefix="Message"
                    field="message"
                    errors={state.errors}
                  />
                  <svg
                    className="absolute top-4 left-3 h-6 w-6 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="none"
                    stroke="currentColor"
                  ></svg>
                </div>

                <div className="mt-4 mb-2 sm:mb-4">
                  <button
                    type="submit"
                    className="inline-flex items-center justify-center w-full bg-blankShade h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 focus:shadow-outline focus:outline-none"
                  >
                    Submit
                  </button>
                </div>
              </form>
              )
            }
              
            </div>
        </div>
      </div>
    </div>
  );
}
