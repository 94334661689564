import React, { useState } from "react";
import { FaqsImage, Minus, Plus, Search } from "../Assets";
import { Navbar } from "../Components";
import { Footer } from "../UI";
import { Link } from "react-router-dom";

const Item = ({ title, children, isOpen, setIsOpen, onClick }) => {
 const handleClick = () => {
   onClick();
 };

 return (
   <div className="border-bottom rounded shadow-sm">
     <button
       type="button"
       aria-label="Toggle item"
       title="Toggle item"
       className="flex items-center gap-10 w-full p-4 focus:outline-none"
       onClick={handleClick}
     >
       <div
         className={`flex items-center justify-center w-8 h-8 border rounded-full ${
           isOpen ? "bg-nudeColor" : "bg-blankColor"
         }`}
       >
         <img
           src={isOpen ? Minus : Plus}
           alt={isOpen ? "Minus" : "Plus"}
           className="w-3 text-gray-600 transition-transform duration-200"
           style={{
             transform: isOpen ? "rotate(0deg)" : "rotate(-90deg)",
           }}
         />
       </div>
       <p className="text-xl font-medium text-start" id="title">{title}</p>
     </button>
     {isOpen && (
       <div className="p-4 pt-0">
         <p className="text-gray-700">{children}</p>
       </div>
     )}
   </div>
 );
};

export default function Faqs() {
  const [openIndex, setOpenIndex] = useState(null);

  const handleItemClick = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const items = [
    {
      title:
        "What makes Viindo Cart different from traditional e-commerce platforms?",
      content: "XYZ",
    },
    {
      title: "How secure is Viindo’s platform for online transactions?",
      content: "xyz",
    },
    {
      title: "Can businesses of all sizes join Viindo Cart?",
      content:
        "Viindo caters to businesses of varying scales, providing opportunities for growth and engagement.",
    },
    {
      title: "What kind of marketing strategies does Viindo employ?",
      content:
        "Viindo utilizes a multi-channel approach, including digital and traditional methods, to reach a diverse audience",
    },
  ];

  return (
    <div>
      <div className="relative" id="faqsContainer">
        <img
          src={FaqsImage}
          className="absolute inset-0 object-cover w-full h-full"
          alt=""
        />
        <div className="relative bg-lightDark bg-opacity-75">
          <Navbar />
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-80">
            <div className="flex flex-col items-center justify-center">
              <div className="flex flex-col gap-2 mb-20 text-center">
                <h1 className="text-4xl text-whiteShade font-bold">
                  You Have Questions
                </h1>
                <h1 className="text-4xl text-whiteShade font-bold">
                  We Have Answers
                </h1>
              </div>
              <div>
                <form className="flex justify-center items-center w-full mb-4 md:flex-row md:px-16 bg-whiteShade2 rounded-md" id="form">
                  <img src={Search} className="w-5 h-5" />
                  <input
                    placeholder="Search question here"
                    required=""
                    type="text"
                    className="flex-grow w-full h-fit px-20 mb-3 transition duration-200 bg-whiteShade2 border appearance-none md:mr-2 md:mb-0 focus:outline-none focus:shadow-outline"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Faqs */}
      <div class="px-4 py-32 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="max-w-xl sm:mx-auto lg:max-w-4xl">
          <div>
            {items.map((item, index) => (
              <Item
                key={index}
                title={item.title}
                isOpen={openIndex === index}
                setIsOpen={() => handleItemClick(index)}
                onClick={() => handleItemClick(index)}
              >
                {item.content}
              </Item>
            ))}
          </div>
        </div>
      </div>

      {/* Contact Us */}
      <div className="flex flex-col justify-center items-center py-48 gap-5" id="contactFind">
        <h1>Can’t find the answer you need?</h1>
        <p>
          Still have questions unanswered, or you need to make more inquiries?
        </p>
        <ul class="flex items-center space-x-8 lg:flex">
          <li>
            <Link
              to={"/"}
              class="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-green transition duration-200 rounded shadow-md hover:bg-green text-nudeColor border-nudeColor border-2 focus:shadow-outline focus:outline-none"
              aria-label="Contact"
              title="Contact"
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
      {/* Footer */}
      <div className="bg-lightBlank">
        <Footer />
      </div>
    </div>
  );
}
